<template>
  <div>
    <PageTitle v-bind:title="'Scholars'" />

    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col>
          <b-button v-if="config.editable" variant="primary" :disabled="!$store.getters.checkRole('legam.bib.author.add')" size="sm" class="float-right mb-4" v-b-modal.modal-new-author>
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new scholar
          </b-button>
          <b-modal v-if="config.editable" id="modal-new-author" title="New scholar" ok-title="Save" @ok="saveAuthor" size="lg">
            <AuthorNewEdit />
          </b-modal>
          <LegamDataTables
            v-if="config.editable"
            v-bind="$data"
            @selectChanged="selectChanged"
          />
          <LegamDataTablesClientSide
            v-else
            v-bind="$data"
            @click="openDetails"
          />
        </b-col>
      </b-row>
    </b-container>

    <div class="merge-float" v-if="selected.length && config.editable">
      <b-badge href="#" variant="info" class="mr-1" v-for="author in selected" :key="author.id" @click="removeSelected(author)">
        <span v-html="author.name"></span>
        <b-icon icon="trash" aria-hidden="true" class="ml-1"></b-icon>
      </b-badge>
      <span class="btn-legam-xxs btn-legam-xs btn-legam-blue" v-if="selected.length > 1" v-b-modal.modal-merge>
        <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
        Merge authors
      </span>
      <span class="btn-legam-xxs btn-legam-xs" @click="selected = []">
        <b-icon icon="x" aria-hidden="true"></b-icon>
      </span>
      <b-modal id="modal-merge" title="Merge authors" ok-title="Merge" @ok="merge" size="md">
        <b-badge href="#" variant="info" class="mr-1" v-for="author in selected" :key="'modal' + author.id" @click="removeSelected(author)">
          <span v-html="author.name"></span>
          <b-icon icon="trash" aria-hidden="true" class="ml-1"></b-icon>
        </b-badge>
        <b-form-group
          id="main-entity-grp"
          label="Merge to"
          label-for="main-entity"
          class="mt-3"
          description="This author will keep all it's data (other authors will move their data to this author and then disappear)"
        >
          <b-form-select
            v-model="mainEntityId"
            :options="selected.map(author => {
              return {
                'text': author.name,
                'value': author.id
              }
            })"
          ></b-form-select>
        </b-form-group>
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  * >>> .tdAction {
    width: 150px;
  }
  * >>> .tdManuscriptDates {
    width: 130px;
  }

  * >>> .btn-legam-dt-filter:hover ~ * >>> .filter-box {
    display: block;
  }
  .merge-float {
    position: fixed;
    bottom: 15px;
    left: 20px;
    background-color: #bebebe9a;
    padding: 3px 8px 5px;
    border-radius: 3px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  import tdColAction from "@/components/tdColAction"
  import AuthorNewEdit from "@/components/Bibliography/AuthorNewEdit"
  import tdColUUID from "@/components/tdColUUID"
  import HelpStore from '@/store/helpstore.js'
  import config from '@/config.js'

  export default {
    name: 'AuthorList',
    data(){
      return {
        columns: [
          { title: 'ID', field: 'id', sortable: true, searchable: true, tdComp: tdColUUID },
          { title: 'Title', field: 'title', sortable: true, searchable: true },
          { title: this.$t('FirstName'), field: 'firstName', sortable: true, searchable: true },
          { title: this.$t('LastName'), field: 'lastName', sortable: true, searchable: true },
          { title: 'Action', field: 'action', tdComp: tdColAction, thClass: 'tdAction',
            transformData: function(row){
              return [
                { text: null, icon: 'list-ul', id: row.sigleLegam, to: {
                  name: 'AuthorDetails',
                  params: {
                    id: row.id
                  }}
                }
              ]
            }
          }
        ],
        columnsPI: [
          { title: 'Title', field: 'title', sortable: true, searchable: true },
          { title: this.$t('FirstName'), field: 'firstName', sortable: true, searchable: true },
          { title: this.$t('LastName'), field: 'lastName', sortable: true, searchable: true },
        ],
        url: '/authors/datatables',
        key: 1,
        searchableByColumn: true,
        selected: [],
        mainEntityId: null,
        config: config
      }
    },
    computed: {
      ...mapState({
        userSettings: state => state.user.settings
      })
    },
    components: {
      PageTitle,
      LegamDataTables,
      LegamDataTablesClientSide,
      AuthorNewEdit
    },
    methods: {
      saveAuthor(){
        this.$store.dispatch('createAuthor', HelpStore.item).then((response) => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Author created`
          })
          router.push(`/author/${response.data.id}`)
        })
      },
      selectChanged(items){
        this.selected = items
        if (items.length){
          this.mainEntityId = items[0].id
        }
      },
      openDetails(data) {
        router.push(`/author/${data.id}`)
      },
      removeSelected(item) {
        this.selected = this.selected.filter(_item => item.id != _item.id)
      },
      merge() {
        this.$store.dispatch('mergeAuthors', {
          mainAuthorId: this.mainEntityId,
          authorIds: this.selected.filter(author => author.id != this.mainEntityId).map(author => author.id)
        }).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Authors merged`
          })
          this.selected = []
          this.mainEntityId = null
          this.key++
        })
      },
    },
    watch: {
      "userSettings"(){
        this.key++
      }
    }
  }
</script>
